
$playerTypeBg: linear-gradient(180deg, #FF650E 0%, #FF1C0E 100%);

.root-class-design20 {

  .player {
    & .player-type {

      &-wrapper {
        width: 100%;
        min-width: 8vmin;
      //  top: -20px;
        //top: -3vmin;
        position: relative;

        z-index: 50;
        text-align: center;
      }

      &-mazais {
        background: $playerTypeBg;
        color: $primaryTextColour;
        font-size: var(--largeFontSize);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.85);
        border-radius: 88px;
        width: auto;
        min-width: auto;
        height: auto;
        border: 0;
        padding: 3px 10px;
        text-transform: uppercase;
      }

      &-lielais {
        background: $playerTypeBg;
        color: $primaryTextColour;
        font-size: var(--largeFontSize);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.85);
        border-radius: 88px;
        width: fit-content;
        min-width: unset;
        height: auto;
        border: 0;
        padding: 3px 10px 2px;
        text-transform: uppercase;
        background: #4169E1 !important;
      }
    }

    & .player-name {
      display: inline-block;
      font-size: var(--largeFontSize);
      font-weight: 600;
      color: $primaryTextColour;
      text-align: center;
      margin: 0px;
      cursor: default;
      width: 100%;
      padding: 5px 10px;

      background: #000000;
      border-radius: 88px;

      &-current-turn {
        background: #D2110A;
        border-radius: 88px;
      }

      &-wrapper {
        width: 100%;
        min-width: 8vmin;
      //  top: -26px;
        top: -2.5vmin;
        position: relative;
        margin-top: 5px;
        z-index: 20;
        height: auto;

        // for longer player names in single line
        left: 50%;
        transform: translate(-50%, 0);
        width: max-content;
        max-width: 192%;

        &-large {
          & .player-name {
            // background: #D2110A;
            border-radius: 88px;
          }
        }

        .player-current-hand-new {
          z-index: 50;
          position: absolute;
          left: -46px;
          bottom: 0px;

          width: 44px;
          height: 44px;
          background: linear-gradient(180deg, #333333 0%, #121212 100%);

          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
    
          &-image {
            width: 28px;
            height: 28px;
          }
    
          &-text {
            color: white;
            background: rgba(210, 17, 10, 1);
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            border-radius: 50%;
            position: absolute;
            left: -12px;
            top: -6px;
            font-size: 14px;
          }
        }
      }
    }

    & .player-additional-info {
      display: inline-block;
      color: #fff;
      text-align: center;
      margin-top: 0px;
      margin-left: 5px;
    }

    & .player-balance {
      width: 100%;
      min-width: 8vmin;
    //  top: -23px;
      top: -2.3vmin;
      color: $primaryTextColour;
      font-size: var(--smallFontSize);

      background: rgba(0, 0, 0, 0.3);
      border-radius: 88px;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        font-size: var(--smallFontSize);
        line-height: $smallFontSize;

        &-orange {
          color: #FF8A1A;
        }

        &-red {
          color: #D2110A;
        }
      }
    }
  }

  .player-left {
    position: absolute;
    left: 0 !important;
    top: 25% !important;
    max-width: unset !important;
  //  width: calc(100vw / 8.5);

    // @media screen and (max-aspect-ratio: 16/10) {

    // }

    // @media screen and (max-aspect-ratio: 16/12) {

    // }

    // @media screen and (max-aspect-ratio: 16/14) {
    //   left: 5%!important;
    // }

    // @media screen and (max-aspect-ratio: 1/1) {
    //   left: 10%!important;
    // }

    // @media all and (orientation: portrait) {
    //   left: 10%!important;
    // }
  }

  .player-right {
    position: absolute;
    right: 0 !important;
    top: 25% !important;
    max-width: unset !important;
  //  width: calc(100vw / 8.5);

    // @media screen and (max-aspect-ratio: 16/10) {

    // }

    // @media screen and (max-aspect-ratio: 16/12) {

    // }

    // @media screen and (max-aspect-ratio: 16/14) {
    //   right: 5%!important;
    // }

    // @media screen and (max-aspect-ratio: 1/1) {
    //   right: 10%!important;
    // }

    // @media all and (orientation: portrait) {
    //   right: 10%!important;
    // }
  }

  .player-top {
    left: 50% !important;
    transform: translateX(-50%);
    top: 5% !important;
    max-width: unset!important;
  //  width: calc(100vw / 8.5);

    @media screen and (max-aspect-ratio: 16/10) {

    }

    @media screen and (max-aspect-ratio: 16/12) {

    }

    @media screen and (max-aspect-ratio: 16/14) {
      left: 50%!important;
      top: 4%!important;
    }

    @media screen and (max-aspect-ratio: 1/1) {
      left: 50%!important;
      top: 3%!important;
    }

    @media all and (orientation: portrait) {
      left: 50%!important;
      top: 3%!important;
    }
  }

  .player-firstperson {
    max-width: unset!important;
  //  width: calc(100vw / 8.5);
    bottom: 0% !important;
    z-index: 900;
    left: 50% !important;
    transform: translateX(-50%);

  //  width: 17.5%;
    width: 15.3vmin !important;
    min-width: 120px;

    //left: unset !important;
    // margin-left: auto;
    // margin-right: auto;
    position: fixed;
    bottom: 0% !important;
    top: unset;



    &-background {
      position: absolute;

      left: 50%;
      transform: translate(-50%, 0);

      width: 100%;
      height: 100%;

      min-width: 180px;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;


      background-image: url('../../../../../../images/redesign/player/chair-back 1.webp');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center bottom;

      &-shadow {
        position: absolute;
        top: 0;


        background-image: url('../../../../../../images/redesign/player/player-shadow.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
      //  margin-left: 25%;
      //  margin-right: 25%;
        height: 100%;
        pointer-events: none
      }

      &-shadow2 {
        position: absolute;
        top: -20%;


        background-image: url('../../../../../../images/redesign/player/player-shadow2.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 130%;
        margin-left: -10%;
        height: 100%;
        pointer-events: none
      }
    }
  }

  .player-avatar-wrapper {
    min-width: 120px!important;

  }

  .player-image-timer-wrapper {
  //  width: 10.2vmin!important;
  //  height: 10.2vmin!important;
    min-width: 120px!important;
    min-height: 120px!important;

    width: 15.3vmin!important;
    height: 15.3vmin!important;

    position: relative;
    pointer-events: none;
  }

  /* .turn-timer-wrapper {
    width: 10.2vmin!important;
    height: 10.2vmin!important;
    min-width: 120px!important;
    min-height: 120px!important;
    position: relative;
    pointer-events: none;
  } */

}
