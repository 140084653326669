$mobileLandScapeWidth: 1280px;

.root-class-design20 {
    @media screen and (orientation : portrait) {
        .layout-mobile {
            .layout-mobile-body-content {
                .layout-mobile-body-pane {
                    .layout-mobile-body {
                        .layout-mobile-body-top {
                            .layout-subheader {
                                display: none;
                            }

                            .layout-mobile-subheader {
                                display: flex !important;
                                position: fixed;
                                bottom: 46px;
                                left: 10px;
                                right: 10px;
                                margin: 0 auto;
                                background-color: #000;
                                border-radius: 8px;
                                padding: 9px 10px;
                                justify-content: space-between;
                                align-items: center;

                                .create-room-button-wrapper {
                                    padding-left: 0;
                                    padding-right: 0;

                                    button {
                                        padding: 6px 15px;
                                        font-size: var(--defaultFontSize);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        white-space: nowrap;

                                        &::before {
                                            margin-right: 13px;
                                        }

                                        &::after {
                                            margin-left: 13px;
                                        }
                                    }
                                }

                                .rooms-table-header-tools {
                                    padding-left: 0;
                                    padding-right: 5px;
                                    display: flex;
                                    column-gap: 16px;

                                    .filter-image {
                                        width: 14px;
                                        height: 14px;
                                    }

                                    .info-image {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                            }
                        }

                        .layout-mobile-body-main {
                            height: calc(100vh - 370px);
                            min-height: calc(100vh - 370px);
                            background-color: rgba($color: #000000, $alpha: 0.95);
                            border-radius: 17px 17px 0 0;
                            padding: 13px 8px 0px 3px;
                            margin-top: 10px;

                            .layout-mobile-body-main-scrollarea {
                                max-height: 100%;
                                overflow-x: hidden;
                                overflow-y: auto;
                                height: 100%;

                                .menu-table-row-wrapper {
                                    padding-bottom: 6px;
                                    padding-right: 10px;
                                    min-height: auto !important;

                                    .menu-table-row-inner-wrapper {
                                        padding-left: 10px;
                                        padding-top: 2px;
                                        padding-bottom: 2px;

                                        .rooms-table-row {
                                            height: 42px;
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                            align-items: center;

                                            .position-relative {
                                                display: flex;
                                                width: 153px;
                                                align-items: center;
                                            }

                                            .rooms-table-row-speed {
                                                margin-right: 0px;
                                                width: 10px;
                                            }

                                            .rooms-table-row-type-wrapper {
                                                .rooms-table-row-type {
                                                    white-space: nowrap;
                                                }
                                            }

                                            .rooms-table-row-minGames {
                                                right: 3px;
                                                top: 0px;

                                                img {
                                                    width: 38px;
                                                }
                                                .rooms-table-row-minGames-bell-count {
                                                    left: 18px;
                                                    top: 3px;
                                                }
                                            }

                                            .rooms-table-row-pro {
                                                right: 39px;
                                                top: -2px;

                                                img {
                                                    width: 22px;
                                                }
                                            }

                                            .rooms-table-row-join-button-wrapper {
                                                position: relative;
                                                display: flex;
                                                align-items: center;

                                                .btn {
                                                    width: 36px;
                                                    height: 36px;
                                                    background: rgba(0, 0, 0, 0.4);
                                                    border: 1px solid rgba(255, 255, 255, 0.1);
                                                    border-radius: 50%;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    right: 0;
                                                    z-index: 300;

                                                    &::before {
                                                        content: none;
                                                    }

                                                    &::after {
                                                        content: none;
                                                    }
                                                }

                                                .private-room-button {
                                                    img {
                                                        width: 50%;
                                                        object-fit: contain;
                                                    }
                                                }

                                                &>div {
                                                    z-index: 200;
                                                }

                                                .rooms-table-row-password-text {
                                                    white-space: nowrap;
                                                }

                                                .rooms-table-row-password-digit {
                                                    width: 12px;
                                                }
                                            }

                                            .players-row-wrapper {
                                                display: flex;
                                                justify-content: flex-start;
                                                column-gap: 3px;
                                                margin-right: auto;

                                                &>div {
                                                    .rooms-table-row-player {
                                                        .rooms-table-row-player-image-frame {
                                                            margin-right: 0;
                                                            min-width: auto;
                                                            min-height: auto;
                                                            width: 24px;
                                                            height: 24px;

                                                            .rooms-table-row-player-image {
                                                                width: 24px;
                                                                height: 24px;
                                                                object-fit: contain;
                                                            }
                                                        }

                                                        .rooms-table-row-player-image-frame-default {
                                                            border: unset;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            width: 24px;
                                                            height: 24px;
                                                            background: unset !important;

                                                            .rooms-table-row-player-image-default {
                                                                width: 24px;
                                                                height: 24px;
                                                                overflow: hidden;
                                                                padding: 0 !important;
                                                                transform: scale(1.15);
                                                                background: unset;
                                                                box-shadow: unset;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .menu-table-row-inner-wrapper.rooms-table-joined-row {
                                        background-color: rgba($color: #D2110A, $alpha: 0.2) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal {
            .modal-dialog.rooms-table-filter-modal {
                .modal-content {
                    .modal-header {}

                    .modal-body {
                        .filter-modal-body-row {
                            margin-left: 0;
                            margin-right: 0;

                            .filter-modal-body-col {
                                padding: 0;

                                .filter-modal-body-items-wrapper {
                                    margin-left: 0;
                                    height: calc(100vh - 380px);
                                    margin-right: 11px;
                                    margin-bottom: 20px;
                                    overflow-y: auto;
                                    overflow-x: hidden;

                                    .filter-modal-body-item-row {
                                        margin-left: 0;
                                        margin-right: 0;
                                        padding-right: 10px;

                                        .modal-body-row {
                                            padding: 0;

                                            .row {
                                                margin: 0;
                                            }

                                            .switch-select-label {
                                                margin-left: 19px;
                                                margin-top: 20px;
                                                font-size: var(--largeFontSize);
                                                font-weight: 600;
                                                line-height: 136%;
                                                padding-left: 0;
                                            }

                                            .switch-toggle-column-wrapper {
                                                margin-left: 19px;
                                                flex-direction: column;
                                                align-items: flex-start;

                                                .room-filter-scroll-wrapper {
                                                    overflow: auto hidden;
                                                    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.2));
                                                    width: 100%;

                                                    &::-webkit-scrollbar {
                                                        height: 4px;
                                                    }
                                                }
                                                .switch-select {
                                                    // height: unset;
                                                }
                                                .switch-option {
                                                    margin-top: 3px;
                                                    margin-bottom: 3px;
                                                }

                                                .switch-text {
                                                    font-size: var(--largeFontSize);
                                                    font-weight: 400;
                                                    line-height: 160%;
                                                }

                                                >div.display-inline:first-of-type {
                                                    margin-left: 0;
                                                    // overflow: auto hidden;
                                                    max-width: 100%;

                                                    &::-webkit-scrollbar {
                                                        height: 4px;
                                                    }

                                                    .switch-select {
                                                        display: flex;
                                                        flex-wrap: nowrap;

                                                        .switch-select-option {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                                                            .switch-select-option-name {
                                                                white-space: nowrap;
                                                                font-size: var(--defaultFontSize);
                                                                line-height: 136%;
                                                            }
                                                        }
                                                    }
                                                }

                                                // >div.display-inline:first-of-type+div {
                                                //     margin: 12px 0 0 0;
                                                // }

                                                // >div.display-inline:first-of-type+div+div {
                                                //     margin: 12px 0 0 0;
                                                // }

                                                .switch-select {
                                                    display: flex;
                                                    flex-wrap: nowrap;
                                                    max-width: 100%;
                                                    //overflow: auto hidden;

                                                    &::-webkit-scrollbar {
                                                        height: 4px;
                                                    }

                                                    .switch-select-option {
                                                        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.4) !important;
                                                        .switch-select-option-name {
                                                            white-space: nowrap;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .filter-modal-body-item-row:last-of-type {
                                        .modal-body-row {
                                            .switch-toggle-column-wrapper {
                                                margin-bottom: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                }
            }
        }
    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .layout-mobile {
            .layout-mobile-body-content {
                .layout-mobile-body-pane {
                    .layout-mobile-body {
                        .layout-mobile-body-top {
                            .layout-subheader {
                                display: none;
                            }

                            .layout-mobile-subheader {
                                .create-room-button-wrapper {
                                    padding-left: 0;
                                    padding-right: 0;

                                    button {
                                        padding: 6px 15px;
                                        font-size: var(--defaultFontSize);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        white-space: nowrap;

                                        &::before {
                                            margin-right: 13px;
                                        }

                                        &::after {
                                            margin-left: 13px;
                                        }
                                    }
                                }

                                .rooms-table-header-tools {
                                    padding-left: 0;
                                    padding-right: 5px;
                                    display: flex;
                                    column-gap: 16px;

                                    .filter-image {
                                        width: 14px;
                                        height: 14px;
                                    }

                                    .info-image {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                            }
                        }

                        .layout-mobile-body-main {
                            .layout-mobile-body-main-scrollarea {
                                max-height: 100%;
                                overflow-x: hidden;
                                overflow-y: auto;
                                height: 100%;

                                .menu-table-row-wrapper {
                                    padding-bottom: 6px;
                                    padding-right: 10px;
                                    min-height: auto !important;

                                    .menu-table-row-inner-wrapper {
                                        box-shadow: none !important;

                                        .rooms-table-row {
                                            height: 42px;
                                            display: flex;
                                            flex-direction: row;
                                            //justify-content: space-between;
                                            align-items: center;

                                            .position-relative {
                                                display: flex;
                                                width: 155px;
                                                align-items: center;
                                                //margin-right: 30px;
                                                margin-right: 10px;
                                            }

                                            .rooms-table-row-speed {
                                                margin-right: 0px;
                                                width: 10px;
                                            }

                                            .rooms-table-row-type-wrapper {
                                                .rooms-table-row-type {
                                                    white-space: nowrap;
                                                }
                                            }

                                            .rooms-table-row-minGames {
                                                right: 0px;
                                                top: 0px;

                                                img {
                                                    width: 38px;
                                                }
                                                .rooms-table-row-minGames-bell-count {
                                                    left: 18px;
                                                    top: 3px;
                                                }
                                            }

                                            .rooms-table-row-pro {
                                                right: 39px;
                                                top: -2px;

                                                img {
                                                    width: 22px;
                                                }
                                            }

                                            .rooms-table-row-join-button-wrapper {
                                                position: relative;
                                                display: flex;
                                                align-items: center;
                                                margin-left: auto;

                                                .btn {
                                                    width: 36px;
                                                    height: 36px;
                                                    background: rgba(0, 0, 0, 0.4);
                                                    border: 1px solid rgba(255, 255, 255, 0.1);
                                                    border-radius: 50%;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    right: 0;
                                                    z-index: 300;

                                                    &::before {
                                                        content: none;
                                                    }

                                                    &::after {
                                                        content: none;
                                                    }
                                                }

                                                .private-room-button {
                                                    img {
                                                        width: 50%;
                                                        object-fit: contain;
                                                    }
                                                }

                                                &>div {
                                                    z-index: 200;
                                                }

                                                .rooms-table-row-password-text {
                                                    white-space: nowrap;
                                                }

                                                .rooms-table-row-password-digit {
                                                    width: 12px;
                                                }
                                            }

                                            .players-row-wrapper {
                                                display: flex;
                                                justify-content: flex-start;
                                                column-gap: 3px;

                                                &>div {
                                                    .rooms-table-row-player {
                                                        .rooms-table-row-player-image-frame {
                                                            margin-right: 0;
                                                            min-width: auto;
                                                            min-height: auto;
                                                            width: 36px;
                                                            height: 36px;

                                                            .rooms-table-row-player-image {
                                                                width: 36px;
                                                                height: 36px;
                                                                object-fit: contain;
                                                            }
                                                        }

                                                        .rooms-table-row-player-image-frame-default {
                                                            border: none;
                                                            background: unset !important;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;

                                                            .rooms-table-row-player-image-default {
                                                                padding: 0 !important;
                                                                width: 36px;
                                                                height: 36px;
                                                                transform: scale(1.1);
                                                                overflow: hidden;
                                                                background: unset !important;
                                                                box-shadow: unset !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .menu-table-row-inner-wrapper.rooms-table-joined-row {
                                        background-color: rgba($color: #D2110A, $alpha: 0.2) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal {
            .modal-dialog.rooms-table-filter-modal {
                width: 78vw;
                .modal-content {
                    .modal-header {}

                    .modal-body {
                        .filter-modal-body-row {
                            margin-left: 0;
                            margin-right: 0;

                            .filter-modal-body-col {
                                padding: 0;

                                .filter-modal-body-items-wrapper {
                                    margin-left: 0;
                                    height: 43vh;
                                    margin-right: 11px;
                                    margin-bottom: 20px;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    &-in-app-frame {
                                        height: 67vh !important;
                                    }

                                    .filter-modal-body-item-row {
                                        margin-left: 0;
                                        margin-right: 0;
                                        padding-right: 10px;

                                        .modal-body-row {
                                            padding: 0;

                                            .row {
                                                margin: 0;
                                            }

                                            .switch-select-label {
                                                margin-left: 19px;
                                                margin-top: 20px;
                                                font-size: var(--largeFontSize);
                                                font-weight: 600;
                                                line-height: 136%;
                                                padding-left: 0;
                                            }

                                            .switch-toggle-column-wrapper {
                                                margin-left: 19px;
                                                flex-direction: column;
                                                align-items: flex-start;

                                                .switch-text {
                                                    font-size: var(--largeFontSize);
                                                    font-weight: 400;
                                                    line-height: 160%;
                                                }
                                                .switch-option {
                                                    margin-top: 3px;
                                                    margin-bottom: 3px;
                                                }

                                                >div.display-inline:first-of-type {
                                                    margin-left: 0;
                                                    // overflow: auto hidden;
                                                    max-width: 100%;

                                                    &::-webkit-scrollbar {
                                                        height: 4px;
                                                    }

                                                    .switch-select {
                                                        display: flex;
                                                        flex-wrap: nowrap;

                                                        .switch-select-option {
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;

                                                            .switch-select-option-name {
                                                                white-space: nowrap;
                                                                font-size: var(--defaultFontSize);
                                                                line-height: 136%;
                                                            }
                                                        }
                                                    }
                                                }

                                                // >div.display-inline:first-of-type+div {
                                                //     margin: 12px 0 0 0;
                                                // }

                                                // >div.display-inline:first-of-type+div+div {
                                                //     margin: 12px 0 0 0;
                                                // }

                                                .switch-select {
                                                    display: flex;
                                                    flex-wrap: nowrap;
                                                    max-width: 100%;
                                                    // overflow: auto hidden;

                                                    &::-webkit-scrollbar {
                                                        height: 4px;
                                                    }

                                                    .switch-select-option {
                                                        .switch-select-option-name {
                                                            white-space: nowrap;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .filter-modal-body-item-row:last-of-type {
                                        .modal-body-row {
                                            .switch-toggle-column-wrapper {
                                                margin-bottom: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                }
            }
        }
    }
}

.root-class-design20-light {
    @media screen and (orientation : portrait) {
        .layout-mobile {
            .layout-mobile-body-content {
                .layout-mobile-body-pane {
                    .layout-mobile-body {
                        .layout-mobile-body-main {
                            .layout-mobile-body-main-scrollarea {
                                .menu-table-row-wrapper {
                                    .menu-table-row-inner-wrapper {
                                        .rooms-table-row {
                                            .rooms-table-row-join-button-wrapper {
                                                .btn {
                                                    background: rgba(255, 255, 255, 0.4) !important;
                                                    border: 1px solid rgba(0, 0, 0, 0.1) !important;
                                                }
                                            }

                                            .players-row-wrapper {
                                                &>div {
                                                    .rooms-table-row-player {
                                                        .rooms-table-row-player-image-frame-default {

                                                            .rooms-table-row-player-image-default {
                                                                border: none !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        .layout-mobile {
            .layout-mobile-body-content {
                .layout-mobile-body-pane {
                    .layout-mobile-body {
                        .layout-mobile-body-main {
                            .layout-mobile-body-main-scrollarea {
                                .menu-table-row-wrapper {
                                    .menu-table-row-inner-wrapper {
                                        .rooms-table-row {
                                            .rooms-table-row-join-button-wrapper {
                                                .btn {
                                                    background: rgba(255, 255, 255, 0.4);
                                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                                }
                                            }

                                            .players-row-wrapper {
                                                &>div {
                                                    .rooms-table-row-player {
                                                        .rooms-table-row-player-image-frame-default {
                                                            background: rgba($color: #fff, $alpha: 0.5);

                                                            .rooms-table-row-player-image-default {
                                                                background: linear-gradient(180deg, #FFFFFF 0%, #ECECEC 100%);
                                                                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                                                                border: none !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .menu-table-row-inner-wrapper.rooms-table-joined-row {
                                        background-color: rgba($color: #D2110A, $alpha: 0.2) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 670px) {
    .rooms-table-row-player-name {
        display: none !important;
    }
}
