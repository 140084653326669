.buy-page {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  padding: 10px 5px 5px 10px;
  height: 390px;

  .glitter-anim-image {
    position: absolute;
    z-index: 999;
    & div {
      cursor: default;
    }
  }

  &-header {
    height: 35px;

    &-image {
      display: inline-block;
      margin-right: 10px;
    }

    &-text {
      text-transform: uppercase;
      font-size: var(--largeFontSize);
      color: #fff;
      margin-top: 5px;
      display: inline-block;
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      height: auto;
      min-height: 30px;
      
      @include lt-md {
        font-size: var(--smallFontSize);
      }

      @include md {
        font-size: var(--mSmallFontSize);
      }

      &-wrapper {
        padding-left: 5px;
        padding-right: 5px;
      }

      &-active {
        color: #fff;
        background-color: #380000!important;
        border-color: #fff;
        box-shadow: none;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  &-main {
    // height: 55%;
    margin-bottom: 15px;
    margin-top: 40px;
  }

  &-send {
    margin-top: 5px;
  }

  &-coins {

    &-background {
      height: 60px;
      width: 60px;
      position: absolute;
      z-index: 1;
      background-color: #c44c4c;
      border-radius: 50%;
      margin: auto;
      top: 20px;
      left: 20px;
    }

    &-button {
      width: 90%;
      max-width: 140px;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 10px;

      background-color: #c44c4c;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:disabled {
        color: white;
      }

      @include lt-md {
        font-size: var(--smallFontSize);
      }

      @include md {
        font-size: var(--mSmallFontSize);
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &-text {
      //  color: #fff;
        text-align: center;
      }

      &-wrapper {
        text-align: center;
      //  display: inline-block;
      //  width: 100%;
        max-width: 120px;
        margin: auto;
        position: relative;
        
        & .buy-page-coins-wrapper-bonus-coins-amount {
          background: #380000;
          border-radius: 99px;
          position: absolute;
          width: auto;
          padding: 1px 5px;
          right: 0px;
          top: -50px;
          font-size: 12px;
          z-index: 100;
          z-index: 100;
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          align-items: center;
          & img {
            width: 16px;
            height: 14px;
            margin-right: unset;
          }

          & span {
            margin-right: 4px;
          }
        }
      }
    }

    &-amount {
      color: #FFD34E;
      text-align: center;
      display: inline-block;
      font-size: var(--defaultFontSize);
    }

    &-coin {
      text-align: center;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    &-wrapper {
      text-align: center;
      max-width: 160px;
      margin: auto;
      position: relative;
        
      &-bonus-coins-amount {
        background: #380000;
        border-radius: 99px;
        position: absolute;
        width: auto;
        padding: 1px 5px;
        right: 0px;
        top: -17px;
        font-size: 12px;
        z-index: 100;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        & img {
          width: 16px;
          height: 14px;
          margin-right: unset;
        }

        & span {
          margin-right: 4px;
        }
      }
    }

    &-bonus-image {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 5;
    }

    &-image1 {
      position: relative;
      width: 40px;
      height: 40px;
      top: 32px;
      margin:auto;
      z-index: 25;
    }

    &-image2 {
      position: relative;
      width: 50px;
      height: 50px;
      top: 32px;
      margin:auto;
      z-index: 25;
    }

    &-image3 {
      position: relative;
      width: 80px;
      height: 80px;
      top: 20px;
      margin:auto;
      z-index: 25;
    }

    &-image4 {
      position: relative;
      width: 90px;
      height: 90px;
      top: 15px;
      margin:auto;
      z-index: 25;
    }

    &-image5 {
      position: relative;
      width: 105px;
      height: 105px;
      top: -5px;
      margin:auto;
      z-index: 25;
    }

    &-imageWrapper {
      width: 100px;
      height: 100px;
      position: relative;
      margin:auto;
    }
  }


  &-premium {
    &-button {
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;

      background-color: #c44c4c;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      @include lt-md {
        font-size: var(--smallFontSize);
      }

      @include md {
        font-size: var(--mSmallFontSize);
      }

      &:disabled {
        color: #FFF;
        opacity: 0.70;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #c44c4c;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &-text {
      //  color: #fff;
        text-align: center;
      }
    }

    &-amount {
      color: #FFD34E;
      text-align: center;
      font-size: var(--largeFontSize);
      display: inline-block;
      // white-space: nowrap;
    }

    &-wrapper {
      text-align: center;
      display: inline-block;
      width: 100%;
      position: relative;
      margin: auto;
    }

    &-button-wrapper {
      button {
        white-space: pre;
      }
    }

    &-image1 {
      position: relative;
      width: 60px;
      top: 18px;
    //  margin-left: 35px;
      margin: auto;
      z-index: 25;
    }

    &-image2 {
      position: relative;
      width: 65px;
      top: 20px;
      left: 3px;
    //  margin-left: 35px;
      margin: auto;
      z-index: 25;
    }

    &-image3 {
      position: relative;
      width: 75px;
      top: 18px;
      left: -4px;
    //  margin-left: 35px;
      margin: auto;
      z-index: 25;
    }

    &-imageWrapper {
      width: 100px;
      height: 100px;
      position: relative;
      margin: auto;
    }
  }

  &-info {

    &-imageWrapper {
      position: absolute;
      width: 17px;
      height: 17px;
      margin-left: 10px;
      cursor: pointer;
      display: inline-block;
    }

    &-image {
      width: 17px;
      height: 17px;
      top: 5px;
      position: relative;
      z-index: 9;
    }

    &-hover {
      position: absolute;
      top: 0px;
      left: 25px;
      font-size: var(--defaultFontSize);
      border: 1px solid #FFF;
      box-shadow: 5px 5px 0px 0px #380000;
      background-color: #2c5955;
      padding: 6px 12px;
      width: 200px;
    //  height: 30px;
      z-index: 999;
    }

    &-hover-2 {
      position: absolute;
      top: -55px;
      left: 25px;
      font-size: var(--defaultFontSize);
      border: 1px solid #FFF;
      box-shadow: 5px 5px 0px 0px #380000;
      background-color: #2c5955;
      padding: 6px 12px;
      width: 160px;
      width: 240px;
    //  height: 30px;
      z-index: 999;

      right: 25px;
      left: unset;

      @media screen and (max-width: 1200px) {
        right: 25px;
        left: unset;
      }
    }

  }

  &-title {

    &.nav-link {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: var(--mLargeFontSize);
      line-height: 24px;

      margin-top: 10px;

      letter-spacing: 0.257143px;

      color: #EDF75A;
      border: 0;

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

      &.active {
        color: #EDF75A!important;
        border: 0!important;
        background-color: transparent!important;
      }

      &:hover {
        border: 0;
      }

      &:focus {
        border: 0;
      }
    }
  }

  &-block {
    //  background: #000000;
  //  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  //  border-radius: 20px;
  //  padding: 5px;
  //  height: 335px;
    width: 100%;

  //  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);

  //  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.75, rgba(252, 255, 182, 0.6)), color-stop(0.25, rgba(252, 255, 182, 0.1)));
    padding: 1px;

    &-container {
    //  background: #000000;
      height: 100%;
      position: relative;

      &-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    &-background {
    //  @include background-2x( '../../images/BuyMoney/Buy options bckgr desktop', 'png', 980px, 700px, top center, no-repeat );
    //  background-size: cover;
    //  background-position: top center;
      //border-bottom: 1px solid rgba(252, 255, 182, 0.6);

    /*  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0px;
      left: 0px; */
    }

    &-row {
      padding-top: 10px;
      margin-left: 5px;
    }

    & .amount {
      text-align: right;
      padding: 0;
    }

    & .price {
      text-align: center;
      padding-left: 0;
      padding-right: 20px;
    }

    & .yellow {
      color: #EDF75A;
    }

    &-text {
      padding: 0;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: var(--xLargeFontSize);
      line-height: 26px;
      margin-top: 12px;

      letter-spacing: 0.285714px;

      color: #FFFFFF;

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    }

    &-coin {
    //  position: absolute;
      right: -5px;
      top: 60%;
      width: 50px;
      height: 50px;
      z-index: 5;

      &-image {
        position: absolute;
        width: auto;
        height: 100%;
      }

      &-text {
        position: absolute;
        top: 8px;
        left: 30px;
        font-size: var(--xLargeFontSize);
        // font-size: 22px;
        font-weight: 600;
        color: #DFA11B;
      }
    }

    &-button {
        & .buy-money-button {
          background: linear-gradient(180deg, #FFD400 2.43%, #CA9318 100%);
          border-radius: 3px;
          height: 42px;
          width: 70%;
          border-radius: 1px;
          margin-left: 10%;
          margin-right: 15%;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: var(--defaultFontSize);
          // font-size: 13px;
          line-height: 18px;
          letter-spacing: 0.2px;
          color: #FFFFFF;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

          padding-left: 0;

          &-image {
            width: 40%;
             position: relative;
             float: left;
          }

          &-text {
            width: 60%;
            position: relative;
            float: right;
            margin-top: 6px;
          }
        }
      }
  }

  &-tabContent {
    height: 100%;

  }

  &-tab {
    height: 100%;
  }

  &-history-row {
    max-width: 100%;
    overflow: hidden;
  }
}


.inbox-payment-modal {
  top: 0px !important;
  max-width: 900px;
  min-width: 900px;
  margin-top: 30px;

  .h2 {
    font-size: 1.7rem;
  }

  .pt-5 {
    padding-top: 1rem!important;
  }

  .modal-header {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .modal-body {
    padding-top: 0px;
  }

  .modal-dialog {
    top: 25px;
  }
}
