.start-new-game {
  position: relative;
  //  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000000 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 4px;
  background: #FED75F;
  border: 2px solid #FFFFFF;
  -webkit-box-shadow: 4px 4px 0px 0px #380000;
  -moz-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 4px 4px 0px 0px #380000;

  &-wrapper {
    //  height: 390px;
    min-height: 410px;
  }
  /*  & .block-icon {
    @include background-2x( '../../images/Start game block icon', 'png', 70px, 50px, center center, no-repeat );
    width: 70px;
    height: 50px;
    position: relative;
    margin: auto;
    top:-20px;
    margin-bottom: -20px;
    left: 0px;
  } */

  .sng {
    &-choose-bet {
      padding-top: 8px;

      > div {
        display: inline-block;
      }

      .dropdown {
        right: 0;
        float: right;
      }

      &-label {
        float: left;
        font-style: normal;
        font-weight: bold;
        font-size: var(--largeFontSize);
        line-height: 20px;
        color: #2E5A56;
      }
    }
  }

  & .new-game {
    font-style: normal;
    font-weight: bold;
    font-size: var(--xLargeFontSize);
    line-height: 28px;
    color: #2E5A56;
    text-align: left;
    margin: auto;
    text-transform: uppercase;
    padding-bottom: 6px;
  }

  & .game-type {
    font-size: var(--defaultFontSize);
    // font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0 1px 0px 0px rgba(255,255,255,.1), 0 -1px 0px 0px rgba(255,255,255,.1);

    &-text {
      margin-left: 5px;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      line-height: 18px;
      color: #fff;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

      &-ru {
        font-size: var(--mSmallFontSize);
        margin-left: 3px;
      }

      &-info {
        &-image {
          width: 17px;
          height: 17px;
          top: 1px;
          position: relative;
          z-index: 9;
        }

        &-imageWrapper {
          position: absolute;
          width: 17px;
          height: 17px;
          margin-left: 5px;
          margin-right: 5px;
          cursor: pointer;
          display: inline-block;
        }

        &-hover {
          position: absolute;
          top: -5px;
          left: 25px;
          font-size: var(--defaultFontSize);
          border: 1px solid #FFF;
          box-shadow: 5px 5px 0px 0px #380000;
          background-color: #2c5955;
          padding: 6px 12px;
          width: 210px;
          z-index: 999;
        }
      }
    }

    &-input {
      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      & .checkmark {
        background: #FFFFFF;
        border-radius: 3px;
        margin-top: 5px;
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
      }

      & .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      & input:checked ~ .checkmark:after {
        display: block;
      }

      & .checkmark:after {
        //  @include background-2x( '../../images/Checkbox selector only-o', 'png', 16px,12px, center center, no-repeat );
        background-image: url('../../../../images/Checkbox selector only-o.png');
        width: 16px;
        height: 12px;
        position: absolute;
        top: 3px;
        left: 1px;
      }
    }
  }

  & .game-bet {
    font-size: var(--defaultFontSize);
    // font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    margin: auto;
    padding-left: 53px;
    padding-right: 55px;
    margin-bottom: 7px;
    box-shadow: 0 1px 0px 0px rgba(255,255,255,.1), 0 -1px 0px 0px rgba(255,255,255,.1);

    &-select {
      border: 1px solid #fff;
      height: 28px;
      width: 80%;
      padding-top: 2px;
      //  background: linear-gradient(360deg, #222222 0%, #313131 31.49%, #343434 44.94%, #646464 70.39%, #ADADAD 96.4%);
      border-radius: 3px 0px 0px 3px;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      line-height: 18px;
      color: #FFF;
      margin: auto;
      background: #2c5955;

      & option {
        background-color: #2c5955;
        ;
      }
    }
  }


  & .game-type {

    &-wrapper {
      width: 87%;
      margin: auto;
      background: #2E5A56;
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      padding: 8px 5px;
    }

    &-selected {
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      font-weight: 500;
      color: #2c5955;
      margin-left: 15px;
      margin-bottom: 10px;

      &-div {
        display: inline-block;
      }
    }

    &-select {
      font-style: normal;
      font-weight: 400;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      line-height: 18px;
      color: #FFFFFF;
      padding-left: 10px;
      padding-bottom: 1px;
    }
  }



  & .start-game-button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    background-color: #c44c4c;
    border-radius: 15px;
    height: 30px;
    font-size: var(--defaultFontSize);
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    -webkit-box-shadow: 5px 5px 0px 0px #380000;
    -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 0px 0px #380000;
    pointer-events: auto;
    cursor: default;

    &:hover {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #c44c4c;
      text-decoration: none;
    }

    &:focus {
      color: #FFD34E;
      border-color: #FFD34E;
      background-color: #c44c4c;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #380000;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

.gameType {
  width: var(--specialShapeSize);
  height: var(--xxLargeFontSize);
  background: #C4C4C4;
  border-radius: 2px;

  &-text {
    margin: auto;
    font-style: normal;
    font-weight: 600;
    font-size: var(--largeFontSize);
    height: var(--xxLargeFontSize);
    text-align: center;
    color: #1E413D;
  }
}

.room-password {

  &-digit {
    font-size: var(--xxLargeFontSize);
    // font-size: 26px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    width: 40px;
    height: 40px;
    background: #fff;
    color: #000 !important;
    border-radius: 5px;
    margin-left: 3px;
    ;
    margin-right: 3px;
    //  color: transparent;
    //  text-shadow: 0 0 0 gray;
    //  text-align: center;
    //  &:focus {
    //    outline: none;
    //  }
    // &[type=number]::-webkit-inner-spin-button,
    // &[type=number]::-webkit-outer-spin-button {
    //     -webkit-appearance: none;
    //     margin: 0;
    // }
    // &[type=number] {
    //     -moz-appearance: textfield; /* Hide the arrows in Firefox */
    // }
  }

  &-digit::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &-error {
    font-size: var(--defaultFontSize);
    // font-size: 13px;
  }
}


.created-room {
  font-style: normal;
  font-size: var(--mLargeFontSize);
  // font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
  color: #2c5955;

  &-code {
    width: 190px;
    margin-left: -5px;

    &-title {
      width: 100%;
      font-style: normal;
      font-size: var(--headerFontSize);
      text-align: center;
      margin: auto;
      color: #2c5955;
    }
  }

  &-div {
    height: auto;
    display: inline-block;
    margin-right: 4px;
    color: #000;
    vertical-align: middle;
    font-size: var(--defaultFontSize);

    & .minGames {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      cursor: default;

      &-text {
        width: 26px;
        height: 20px;
        position: absolute;
        top: 8px;
        text-align: center;
        margin: auto;
        font-style: normal;
        font-weight: 600;
        font-size: var(--defaultFontSize);
        // font-size: 13px;

        text-align: center;
        letter-spacing: 0.5px;
        color: #1E413D;
      }

      &-img {
        width: 26px;
        height: 26px;
      }
    }
  }
}
