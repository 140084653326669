/*rules-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;

  &-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:hover {
    background-color: transparent;
  }
}


.help-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;

  &-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:hover {
    background-color: transparent;
  }
}

.tutorial-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;

  &-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:hover {
    background-color: transparent;
  }
}

.moneyHistory-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;

  &-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:hover {
    background-color: transparent;
  }
}

.bannedUsers-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;

  &-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:hover {
    background-color: transparent;
  }
}

.onlineUsers-button {
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;

  &:hover {
    background-color: transparent;
  }
}*/

.rules-modal {

  &-header {
    font-size: var(--headerFontSize);
  }

  &-body {
    max-height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background: #14100d;
      border-radius: 3px;
      border-left: 7px solid #14100d;
      border-right: 7px solid #14100d;
    }

    &::-webkit-scrollbar-thumb {
      background: #1a1b1b;
      border-radius: 3px;
      border-left: 5px solid #14100d;
      border-right: 5px solid #14100d;
    }

    &-table {

      &-header {
        border: 1px solid #1a1b1b;
      }

      &-data {
        border: 1px solid #1a1b1b;
      }
    }
  }

  &-footer {


  }
}

.bottom-bar {
  color: #fff;
//  background: linear-gradient(180deg, #443013 0%, #211e17 100%);
  border-radius: 3px;
  border-color: #3c2f13;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &-rules-page {
    margin-top: -11px;
  }

  &-light {
    color: #000;
    & .bottom-bar-links {
      > a {
        span {
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
}

.incoming-mWeb::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgba(210, 16, 10, 1);
  opacity: 1 !important;
  position: absolute;
  border-radius: 50%;
  right: 3px;
  top: 6px;
}

.incoming-mWeb-chat::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgba(210, 16, 10, 1);
  opacity: 1 !important;
  position: absolute;
  border-radius: 50%;
  right: -1px;
  top: -1px;
}

.incoming-mWeb-landscape::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgba(210, 16, 10, 1);
  opacity: 1 !important;
  position: absolute;
  border-radius: 50%;
  left: 27px;
  top: 2px;
}

.bottom-bar-links {
  font-size: var(--defaultFontSize);
  padding: 0;
  padding-top: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  &-help {
    position: relative;
  }
  &-notification {
    position: relative;
  }

  & .incoming-text {
    opacity: 1 !important;
  }

  &-help.incoming::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: rgba(210, 16, 10, 1);
    opacity: 1 !important;
    position: absolute;
    border-radius: 50%;
    right: 3px;
    top: 3px;
  }

  &-notification.incoming-old::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: rgba(210, 16, 10, 1);
    opacity: 1 !important;
    position: absolute;
    border-radius: 50%;
    right: 0px;
    top: 0px;
  }

  &-notification.incoming-dropdown::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: rgba(210, 16, 10, 1);
    opacity: 1 !important;
    position: absolute;
    border-radius: 50%;
    top: 0px;
  }

  > a {
    display: inline-flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;

    min-width: 0;

    &:hover {
      color: #FFD34E;
    //  border-color: #FFD34E;
    //  background-color: #2c5955;
      text-decoration: none;

      > span {
        color: #FFD34E;
      }
    }

    // &:focus {
    //   color: #FFD34E;
    // //  border-color: #FFD34E;
    // //  background-color: #2c5955;
    //   text-decoration: none;

    //   > span {
    //     color: #FFD34E;
    //   }
    // }

    &:active {
      color: #fff;
    //  background-color: #380000;
    //  -webkit-box-shadow: none;
    //  -moz-box-shadow: none;
    //  box-shadow: none;
    }

    > img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }

    > img.balance-hist-ico {
      width: 29px;
    }

    > span {
      font-style: normal;
      font-weight: 200;
      font-size: var(--mSmallFontSize);
      line-height: 18px;
      color: #FFF;
      margin-right: 7px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
      flex: 1;
    }

    > span, img {
      display: inline-block;
      vertical-align: middle;
    }
  }

//  > a:active, a:focus, a:hover {
//    text-decoration: none;
//    color: #fff;
//  }
}

.about-modal {

  & .about-modal-header {

    & .modal-title {
      font-size: var(--headerFontSize) !important;
      font-weight: bold;
      margin-left: 20px;
      margin-top: 15px;
      color: rgb(254, 215, 95) !important;
    }

    &-version {
      font-size: var(--largeFontSize) !important;
      font-weight: normal !important;
      color: white !important;
      margin-top: 15px;
    }
  }

  &-body {
    text-align: left;
    padding: 0.5rem 0.5rem !important;
    margin-left: 20px;
    margin-right: 20px;

    &-column {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      &-section {
        display: block;
        margin-bottom: 15px;
      }
    }

    & hr {
      margin-top: 8px;
      background: white;
      opacity: 10%;
    }

    & p {
      font-size: var(--defaultFontSize);
      margin-bottom: 0px;
    }

    & span {
      color: rgb(254, 215, 95);
      font-size: var(--defaultFontSize);
    }

    & a {
      color: rgb(254, 215, 95);
      font-size: var(--defaultFontSize);
    }
  }

  &-apps {
    padding: 0.5rem 0.5rem !important;

    margin-left: auto;
    margin-right: auto;
    text-align: center;
    // margin-left: 20px;
    // margin-right: 20px;

    &-img {
      display: inline-block;
      width: 119.5px;
      height: 34.3px;
      margin-left: 0px;
      cursor: pointer;
    }
  }

  &-close-button {
    margin-left: 0px !important;
  }
}
