
.rdw-editor-wrapper {
  width: 100%;
}

.rdw-editor-toolbar {
  color: #000;
}

.rdw-editor-main {
  background-color: white;
  color: #000;
  min-height: 200px;
  padding-left: 10px;
  padding-right: 10px;

  & a {
    color: #000;

    &:hover {
      color: #000;
    }
  }

  & .rdw-link-decorator-wrapper {
    color: #000;

    & a {
      color: #000;

      &:hover {
        color: #000;
      }
    }
  }
}

.rdw-link-modal {
  color: #000;
}

.rdw-image-modal {
  color: #000;
}

.rdw-embedded-modal {
  color: #000;
}

.rdw-colorpicker-modal {
  color: #000;
}

.rdw-dropdown-selectedtext {
  color: #000!important;
}

.rdw-dropdownoption-default {
  color: #000!important;
}

.editor-readOnly {
  .rdw-editor-main {
    background-color: transparent;
    color: #000;
    min-height: unset;
    padding: 0px;
  }
}

.blog-editor {
  background-color: black !important;
  span {
    color: white !important;
  }
}

.blog-editor-read-only {
  background-color: transparent !important;
  span {
    background-color: transparent !important;
    color: white !important;
  }
}

