// $bodyFontSize: 1.2vmin;
// $headerFontSize: 1.6vmin;

//$titlePadding: 1vmin 5px 0.2vmin 15px;

//: $menuRowHeight;

// $rowBackground: rgba(255,255,255,0.03);
// $highlightColor: #FFC900;

//$tooltipBgColor: #FFF;
//$tooltipColor: #000;

.root-class-design20 {

  .buy-page {

    &-tab {
      height: 100%;
    }

    &-row {
      position: relative;
      // top: 2%;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      margin-top: 5px;
      margin-bottom: auto;
      width: 80%;
      left: 8%;
      flex-wrap: nowrap;
    }

    &-packs-row {
      position: relative;
      // top: 2%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      margin-top: 5px;
      margin-bottom: auto;
      width: 80%;
      left: 50%;
      flex-wrap: nowrap;
      justify-content: center;
    }

    &-end-time-section {
      font-size: var(--largeFontSize);
    }

    &-coins-coin-amount {
      display: flex;
      align-items: center;
    }

    &-coins {
      &-image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      &-imageWrapper {
        //  width: 100%;
        //  display: inline-block;
        //  height: auto;

        height: 50%;
        width: auto;
        position: relative;
        margin: auto;
        cursor: pointer;
      }



      &-wrapper {
        position: relative;
        top: -4vmin;

        &-bonus-coins-amount {
          background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
          border-radius: 99px;
          position: absolute;
          width: auto;
          padding: 0 5px;
          right: -14px;
          top: -10px;
          font-size: 12px;

          & img {
            width: 11px;
            height: 10px;
          }
        }
      }

      &-button-wrapper {
        position: relative;
        top: -4vmin;
        text-align: center;
        max-width: none;
      }

      &-bonus-image {
        top: -42px;
        transform: scale(1.2);
      }
    }

    &-premium {
      &-image {
        height: 100%;
        width: 100%;
      }

      &-imageWrapper {
        //  width: 100%;
        //  display: inline-block;
        //  height: auto;

        height: 50%;
        width: auto;
        position: relative;
        margin: auto;
        cursor: pointer;

        &-disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      &-wrapper {
        position: relative;
        top: -1.5vmin;
      }

      &-button-wrapper {
        position: relative;
        top: -1.5vmin;
        text-align: center;
        max-width: none;

        & .buy-page-coins-wrapper-bonus-coins-amount {
          background: linear-gradient(180deg, #FF0000 0%, #790000 100%);
          border-radius: 99px;
          position: absolute;
          width: auto;
          padding: 0 5px;
          right: -25px;
          top: -1px;
          font-size: 12px;
          z-index: 100;
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          align-items: center;

          & img {
            width: 11px;
            height: 10px;
            margin-right: unset;
          }

          & span {
            margin-right: 4px;
          }
        }
      }

      &-bonus-image {
      }
    }

    &-info {

      &-image {
        width: 56px;
        height: 56px;
        top: -10px;
      }

      &-hover {
        background-color: $bgColor;
        border-radius: 1.5vmin 1.5vmin 1.5vmin 1.5vmin;
        width: 15vw;
        min-width: 100px;
        top: 15px;
        box-shadow: none;
        font-size: var(--defaultFontSize);
        border: 0;
        color: $secondaryTextColour;
        transform: translateY(-50%);
        pointer-events: none;
        left: 33px;

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 8px);
          left: -5px;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #fff transparent;
          //rotate: -90deg;
          transform: rotate(-90deg);
        }

        & label {
          color: #D2110A;
          margin-bottom: 0px !important;
        }
      }

      &-imageWrapper {
        & .buy-page-info-image {
          cursor: pointer;
          left: -17px;
        }
      }
    }

    &-redeemGiftCode {
      // width: 100%;
      height: 100%;

      &-section {
        display: flex;
        flex-direction: column;

        &-message {
          // width: 100%;
          height: 30%;

          &-label {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: var(--headerFontSize);
            flex-direction: column;

            &-code {
              color: #FFC900;
              margin-bottom: 20px;
            }

            &-content1 {
              margin-bottom: 20px;
              margin-top: 10px;
            }

            @media screen and (max-height: 650px) {
              &-content1 {
                margin-bottom: 7px;
              }
            }
          }
        }

        @media screen and (max-height: 850px) {
          &-message {
            height: 40%;
          }
        }

        @media screen and (max-height: 650px) {
          &-message {
            // padding-bottom: 10px;
          }
        }

        &-inputCode {
          // width: 100%;
          height: 30%;
          display: flex;
          justify-content: center;
          align-items: center;

          &-col {
            //width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @media screen and (max-height: 850px) {
            &-col {
              padding-bottom: 20px;
            }
          }

          @media screen and (max-height: 650px) {
            &-col {
              //padding-bottom: 40px;
            }
          }
        }

        &-buttonSubmit {
          // width: 100%;
          height: 40%;

          &-col {
            //width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            &-button {
              width: 233px;
              height: 47px;
              font-size: var(--headerFontSize);
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  & .buy-page-tab-discounts {

    @media screen and (max-height: 768px) {
      height: 90%;

      & .buy-page-row {
        top: 52%;
      }

      & .buy-page-coins-image {
        height: 65%;
      }

      & .buy-page-coins-wrapper {
        padding: 3px 11px !important;
        top: -6.4vmin !important;
      }

      & .buy-page-coins-button-wrapper {
        top: -6.2vmin !important;
      }

      & .buy-page-coins-bonus-image {
        transform: scale(0.6) !important;
      }
    }
  }

  .buy-page-end-time-section + .buy-page-row {
    div.col:last-of-type {
      .buy-page-coins-imageWrapper {
        // top: -7px;
      }
    }
  }
}

.root-class-design20-light {
  .buy-page-end-time-section+.buy-page-row {
    div.col {
      .buy-page-coins-wrapper {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 88px;
        padding: 5px 11px;
        max-width: 92px;
        top: -0.4vmin;

        .buy-page-coins-amount {
          font-weight: 600;
          color: #fff !important;
        }
      }

      .buy-page-coins-button-wrapper {
        top: -0.2vmin;

        button {
          padding: 6px 15px;
          background: rgba(255, 255, 255, 0.4) !important;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 99px;
          color: #0C0C0C !important;
          font-weight: 700;
          white-space: pre;

          &::before {
            margin-right: 13px;
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-repeat: no-repeat;
            vertical-align: middle;
          }

          &:after {
            margin-left: 13px;
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-repeat: no-repeat;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.root-class-design20-normal {
  .buy-page-end-time-section+.buy-page-row {
    div.col {
      .buy-page-coins-wrapper {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 88px;
        padding: 5px 11px;
        max-width: 92px;
        top: -0.4vmin;

        .buy-page-coins-amount {
          font-weight: 600;
          color: #fff !important;
        }
      }

      .buy-page-coins-button-wrapper {
        top: -0.2vmin;

        button {
          padding: 6px 15px;
          background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 99px;
          color: #fff !important;
          font-weight: 700;
          margin: 0;

          &::before {
            margin-right: 13px;
            background-image: url('../../../../images/redesign/common/karavs.svg');
            //vertical-align: initial;
            background-repeat: no-repeat;
          }

          &:after {
            margin-left: 13px;
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-repeat: no-repeat;
            //vertical-align: initial;
          }
        }
      }
    }
  }
}

.root-class-design20-dark {
  .buy-page-end-time-section+.buy-page-row {
    div.col {
      .buy-page-coins-wrapper {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 88px;
        padding: 5px 11px;
        max-width: 92px;
        top: -0.4vmin;

        .buy-page-coins-amount {
          font-weight: 600;
          color: #fff !important;
        }
      }

      .buy-page-coins-button-wrapper {
        top: -0.2vmin;

        button {
          padding: 6px 15px;
          background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%) !important;
          border: 1px solid #0C0C0C;
          border-radius: 99px;
          color: #fff !important;
          font-weight: 700;
          margin: 0;

          &::before {
            margin-right: 13px;
            background-image: url('../../../../images/redesign/common/kreists.svg');
            //vertical-align: initial;
            background-repeat: no-repeat;
          }

          &:after {
            margin-left: 13px;
            background-image: url('../../../../images/redesign/common/kreists.svg');
            background-repeat: no-repeat;
            //vertical-align: initial;
          }
        }
      }
    }
  }
}

.transaction-history-table-header {
  .player-history-table-header-col.player-history-table-header-col-last {
    flex-direction: row;
    justify-content: center;
    display: flex;
  }
}

.shop-transaction-history-table {
  height: calc(100% - 73px);
  max-height: unset;
  .player-history-table-col.player-history-table-col-last {
    flex-direction: row;
    justify-content: center;
    display: flex;
  }
}

.root-class-design20-light {
  .buy-page-premium-wrapper {
    text-align: center;
    // margin-left: 10px;
    margin-bottom: 5px;
    .buy-page-premium-amount {
      color: #0C0C0C;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: var(--defaultFontSize);
      line-height: 160%;
    }
  }

  .buy-page-premium-button-wrapper {
    // display: flex;
    // justify-content: center;

    .layout-default-button {
      padding: 6px 15px;
      background: rgba(255, 255, 255, 0.4) !important;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 99px;
      color: #0C0C0C !important;
      font-weight: 700;
      white-space: pre;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        margin-right: 13px;
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-repeat: no-repeat;
        vertical-align: middle;
      }

      &:after {
        margin-left: 13px;
        background-image: url('../../../../images/redesign/common/karavs.svg');
        background-repeat: no-repeat;
        vertical-align: middle;
      }
    }

    .layout-redHighlighted-button {
      display: inline-block;
      vertical-align: middle;
      height: calc(100% - 4px);
      /* background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%) !important; */
      border-radius: 99px;
      /* color: #fff !important; */
      font-family: Open Sans;
      font-style: normal;
      font-weight: 900;
      font-size: var(--defaultFontSize);
      line-height: 23px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      padding: 3px 8px;
      min-width: 15%;
    }

    .layout-redHighlighted-disabled-button {
      justify-content: center;
      align-items: center;
      display: inline-block;
      vertical-align: middle;
      height: calc(100% - 4px);
      // background: linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
      border-radius: 99px;
      // border: 1px solid #0C0C0C !important;
      // color: #fff !important;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 700;
      font-size: var(--defaultFontSize);
      line-height: 135%;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: 9px;
      padding: 6px 15px;
      width: max-content;
      min-width: 15%;
    }
  }
}
